/*====== All Custom Variable Interduce Here ======*/
$light-yellow:                    #FFF59D ;
$twitter:                         #52AEFF;
$facebook:                        #3B5998;
$google:                          #F13A30;
$pinterest:                       #d32f2f;
$instagram:                       #8d6e63;
$rss:                             #ff9800;
$tumblr:                          #607d8b;
$linkedin:                        #4875B4;
$dribbble:                        #f06292;
$youtube:                         #d50000;
$github:                          #424242;
$skype:                           #4fc3f7;

$colors: () !default;
$colors: map-merge((
  "light-yellow":       $light-yellow,
  "twitter":            $twitter,
  "facebook":           $facebook,
  "google":             $google,
  "pinterest":          $pinterest,
  "instagram":          $instagram,
  "rss":                $rss,
  "tumblr":             $tumblr,
  "linkedin":           $linkedin,
  "dribbble":           $dribbble,
  "youtube":            $youtube,
  "github":             $github,
  "skype":              $skype,
), $colors);

$light-yellow:                         $light-yellow;
$twitter:                              $twitter;
$facebook:                             $facebook;
$google:                               $google;
$pinterest:                            $pinterest;
$instagram:                            $instagram;
$rss:                                  $rss;
$tumblr:                               $tumblr;
$linkedin:                             $linkedin;
$dribbble:                             $dribbble;
$youtube:                              $youtube;
$github:                               $github;
$skype:                                $skype;

$theme-colors: () !default;
$theme-colors: map-merge((
  "light-yellow":    $light-yellow,
  "twitter":         $twitter,
  "facebook":        $facebook,
  "google":          $google,
  "pinterest":       $pinterest,
  "instagram":       $instagram,
  "rss":             $rss,
  "tumblr":          $tumblr,
  "linkedin":        $linkedin,
  "dribbble":        $dribbble,
  "youtube":         $youtube,
  "github":          $github,
  "skype":           $skype
), $theme-colors);

// html font
$html-font-size:                  16px;

// Block Settings
$block-bg:                       $white;
$block-border-radius:            5px;
$block-border:                   none;
$block-spacing:                  1.25rem 1.25rem; //20px 20px;
$block-shadow:                   0 1px 15px 1px rgba(69,65,78,0.08);
$block-hover-shadow:             0 1px 15px 1px rgba(69,65,78,0.08);
$block-margin-bottom:            $grid-gutter-width; //24px
$block-transition:               all 0.4s ease-in-out 0s;

$block-title-color:              $gray-900;
$block-title-spacing:            0.9375rem 1.25rem; //15px 20px;
$block-title-font-size:          1rem; //16px
$block-title-font-weight:        500;
$block-title-transform:          capitalize;

$block-contextual-color:         $gray-600;
$block-contextual-font-size:     1rem;

$block-footer-border-width:      1px;
$block-footer-border-color:      $gray-300;

//Breadcrumb Variable
$breadcrumb-font-size:            1.875rem; //30px;
$breadcrumb-font-color:           $gray-900;

// Dark theme
$dark-bg:                         #2c3644;
$dark-bg-color:                   #fff;
$dark-bg-container:               #303030;
$dark-border-color:               rgba(0, 0, 0, 0.1);
$dark-placeholder:                #303030;
$dark-secondary-bg:               #434f5a;

// Input Fields
$input-lg-height:                 52px;
$input-radius:                    40px;

// Gradient Theme Color
$sidebar-dark-gradient:           linear-gradient(0deg, #464D69 0%, rgba(70, 77, 105, 1) 100%);
$sidebar-dark:                    #2E3344;
$overlay-light:                   rgb(255,255,255);
$gradient-primary:                 linear-gradient(180deg, #5D92F4 0%, #09203F 100%); // Primary
$gradient-purple:                  linear-gradient(0deg, #F53844 0%, #42378F 100%); // Purple
$gradient-success:                 linear-gradient(180deg, #245dc5 0%, #007ADF 100%); // Success
$gradient-warning:                 linear-gradient(0deg, #B02E0C 0%, #FFB70F 100%); // warning
$gradient-danger:                  linear-gradient(0deg, #B02E0C 0%, #FFB70F 100%);// danger


// Navbar Varibale
$navbar-height:                   4rem; //80px;
$navbar-bg:                       $white;
$hamburger-icon-color:            $gray-600;
$navbar-icon-height:              35px;
$navbar-icon-width:               35px;
$navbar-link-color:               $white;

// Footer Variable
$footer-height:                   50px;
$footer-bg:                       $navbar-bg;
$footer-box-shadow:               none;

// Square Box
$square-font-size:                0.5; // 50% relative to the container
$square-box:                      (10, 15, 20, 30, 40, 50, 60, 70, 80, 100, 120);

// Sidebar Variable
$sidebar-width:                   16.25rem; //280px;
$sidebar-box-shadow:              none;

$sidebar-link-fontsize:           1rem; //14px
$sidebar-link-font-weight:        400;
$sidebar-link-font-color:         $gray-900;
$sidebar-link-transform:          capitalize;
$sidebar-link-spacing:            0.9375rem 2rem; //15px 40px;

$sidebar-icon-font-size:          1rem; //14px;

$sidebar-hover-bg:                rgba(0,0,0,0.22);
$sidebar-hover-color:             $white;

$sidebar-submenu-font-size:       1rem; //14px
$sidebar-submenu-font-color:      $gray-900;
$sidebar-submenu-spacing:         0.9375rem 2.5rem 0.9375rem 4.375rem;

// Mini Sidebar Variable
$mini-sidebar-width:              85px;

// Horizontal Menu sidebar Variable
$hoz-menu-bg:                     #FFFFFF;
$hoz-nav-link-color:              #464D69;
$hoz-nav-font-size:               0.875rem; //14px
$hoz-nav-link-spacing:            0.9375rem 1.563rem 0.9375rem 1.563rem; //15px 30px;
$hoz-nav-link-hover-bg:           #FAFAFA;
$hoz-menu-height:                 3.125rem; //50px;
$hoz-sub-menu-width:              15rem;
$hoz-box-shadow:                  0 2px 10px 0 rgba(204,204,204,0.5);
$hoz-sub-menu-box-shadow:         0 2px 15px 0 rgba(0,0,0,0.09);

// Social Link Variable
$social-icon-shadow:              0 0 20px 0 rgba(0,0,0,0.19);

// Weather Widget Variables
$weather-bg:                      url('../../img/weather-1.png');

// boxed layout
$boxed-bg:                        url('../../../img/boxed-bg.png');
$dark-layout-bg:                  url('../../../img/dark-layout-bg.png');

// Table Varibale
$table-tbody-font-size:           14px;
$table-tbody-font-weight:         300;

//Quick link
$dropdown-link-shadow:            0 3px 2px 0 rgba(0,0,0,0.02);
$base-light:                    #FAFAFA;

//Font Weight
$fw-semi-bold:                    500;
$fw-bold:                         700;
$fw-light:                        300;
$fw-normal:                       400;

//Custom Colors
$aqua:                          #E1EBFF;
