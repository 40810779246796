/* styles to system indicadores */
.site-logo {
    background: #009878!important;
}

.rct-sidebar-wrap {
    background: #009878!important;
}

.logo-sidbar {
    width: 80px !important;
}

.rct-sidebar .sidebar-overlay-dark * {
    text-transform: initial !important;
}


.logo-login {
    padding-right: 50px;
    width: 300px;
}

.cgo-color-title {
    padding-right: 50px;
}

.bg-utsem {
    background: #00a48d !important;
}

.btn-login {
    width: 125px;
}

.rct-block-title h4, .faq-page-wrapper .panel-heading h4 {
    text-transform: initial !important;
}

.position_loading {
    right: 0px;
    margin-top: -161px;
    z-index: 10000;
    width: 100%;
    position: fixed;
}

.ico-edit {
    color: #0071e5;
    font-size: 18px;
}

.ico-delete {
    color: #d90606;
    font-size: 18px;
}

.ico-check-enable {
    color: #20ad06;
    font-size: 18px;
}

.ico-check-disabled {
    color: #999d99;
    font-size: 18px;
}

.ico-privilegios {
    color: #e50303;
    font-size: 18px;
}

.display-none {
    display: none;
}

.check-privilegio {
    margin-left: 1.25rem !important;
}

.name-privilegio {
    padding: 0px 0px 0px 30px;
    font-family: sans-serif;
}

.page-title h2 {
    text-transform: initial !important;
}

.title-form {
    background: #009878;
    padding: 3px;
    border-radius: 5px;
    font-size: 15px;
    color: white;
}

.btn-link {
    color: #ffffff;
}

.txt-bd {
    color: #222020 !important;
    font-size: 11px;
    font-weight: 700;
}

.modal-details {
    max-width: 850px !important;
}

.body-modal-details {
    padding: 1.5rem !important;
}

.bg-info {
    background-color: #245dc5 !important;
    border-color: #245dc5 !important;
}

.ico-details {
    color: #1561af;
    font-size: 18px;
}

.ico-download {
    color: white;
    font-size: 18px;
}

.disabled {
    display: none;
}

.c-reprobacion {
    padding: 10px;
    width: 60% !important;
}

.txt-here {
    pointer-events: all;
    color: #1e6dcf !important;
    font-weight: 700;
}

.div-info {
    padding: 3px;
    background: #bedcf1;
    border-radius: 5px;
    color: #10639d;
    font-size: 14px;
}

.div-slider{
    padding: 0px 20px 0px 20px;
}
